/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
 import axios from 'axios';
 
 export default() => {
     axios.defaults.headers.Authorization = 'Bearer ' + localStorage.getItem('authToken')
     return axios.create({
        //https://christmas.scottelectricusa.com/api
        //http://localhost:8082/api
         baseURL:  'https://christmas.scottelectricusa.com/api' // the url of our server
     })
 }