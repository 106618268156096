<template>
    <div>
        <h1 class="display-1">Scott Printables Christmas Items 2024</h1>
    </div>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style scoped>
@font-face {
    font-family: ss;
    src: url('../assets/SantasSleighFull.ttf');
}
.display-1 {
    color: #ed1c24;
    margin-bottom: 30px;
    font-family: ss;
}
</style>