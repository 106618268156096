<template>
  <div class="container-fluid">
    <center>
      <Header />
      <div class="card-container">
          <div class="card border border-primary">
              <div class="card-title"><h1>Thank you</h1></div>
              <div class="card-body">
                  <center>
                      <p class="card-text">Your order has been submitted.  Please contact Beth Ferrarini if you have any questions.</p>
                  </center>
              </div>
          </div>
      </div>
    </center>
  </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Header from '@/components/Header.vue'

export default {
  name: 'Thanksyou',
  components: {
    Header
  }
}
</script>

<style scoped>
.container-fluid {
  margin-bottom: 30px;
  background-image: url("../assets/background2.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card {
    width: 90%;
    background:rgba(0,0,0,0.4)
}
.card-title {
    color: #fff;
}
.card-text {
    padding-top: 30px;
    color: #fff;
}
</style>