<template>
    <div class="container-fluid">
        <center>
            <Header />
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 1 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item1.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item1.sQty }}, Adv: {{ totals.item1A.sQty }}, Pal: {{ totals.item1B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.mQty }}, Adv: {{ totals.item1A.mQty }}, Pal: {{ totals.item1B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.lQty }}, Adv: {{ totals.item1A.lQty }}, Pal: {{ totals.item1B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.xLQty }}, Adv: {{ totals.item1A.xLQty }}, Pal: {{ totals.item1B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.xxLQty }}, Adv: {{ totals.item1A.xxLQty }}, Pal: {{ totals.item1B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.xxxLQty }}, Adv: {{ totals.item1A.xxxLQty }}, Pal: {{ totals.item1B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.xxxxLQty }}, Adv: {{ totals.item1A.xxxxLQty }}, Pal: {{ totals.item1B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item1.xxxxxLQty }}, Adv: {{ totals.item1A.xxxxxLQty }}, Pal: {{ totals.item1B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item1.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item1A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item1B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 2 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item2.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item2.sQty }}, Adv: {{ totals.item2A.sQty }}, Pal: {{ totals.item2B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.mQty }}, Adv: {{ totals.item2A.mQty }}, Pal: {{ totals.item2B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.lQty }}, Adv: {{ totals.item2A.lQty }}, Pal: {{ totals.item2B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.xLQty }}, Adv: {{ totals.item2A.xLQty }}, Pal: {{ totals.item2B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.xxLQty }}, Adv: {{ totals.item2A.xxLQty }}, Pal: {{ totals.item2B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.xxxLQty }}, Adv: {{ totals.item2A.xxxLQty }}, Pal: {{ totals.item2B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.xxxxLQty }}, Adv: {{ totals.item2A.xxxxLQty }}, Pal: {{ totals.item2B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item2.xxxxxLQty }}, Adv: {{ totals.item2A.xxxxxLQty }}, Pal: {{ totals.item2B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item2.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item2A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item2B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 3 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item3.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item3.sQty }}, Adv: {{ totals.item3A.sQty }}, Pal: {{ totals.item3B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.mQty }}, Adv: {{ totals.item3A.mQty }}, Pal: {{ totals.item3B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.lQty }}, Adv: {{ totals.item3A.lQty }}, Pal: {{ totals.item3B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.xLQty }}, Adv: {{ totals.item3A.xLQty }}, Pal: {{ totals.item3B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.xxLQty }}, Adv: {{ totals.item3A.xxLQty }}, Pal: {{ totals.item3B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.xxxLQty }}, Adv: {{ totals.item3A.xxxLQty }}, Pal: {{ totals.item3B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.xxxxLQty }}, Adv: {{ totals.item3A.xxxxLQty }}, Pal: {{ totals.item3B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item3.xxxxxLQty }}, Adv: {{ totals.item3A.xxxxxLQty }}, Pal: {{ totals.item3B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item3.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item3A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item3B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 4 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item4.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item4.sQty }}, Adv: {{ totals.item4A.sQty }}, Pal: {{ totals.item4B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.mQty }}, Adv: {{ totals.item4A.mQty }}, Pal: {{ totals.item4B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.lQty }}, Adv: {{ totals.item4A.lQty }}, Pal: {{ totals.item4B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.xLQty }}, Adv: {{ totals.item4A.xLQty }}, Pal: {{ totals.item4B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.xxLQty }}, Adv: {{ totals.item4A.xxLQty }}, Pal: {{ totals.item4B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.xxxLQty }}, Adv: {{ totals.item4A.xxxLQty }}, Pal: {{ totals.item4B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.xxxxLQty }}, Adv: {{ totals.item4A.xxxxLQty }}, Pal: {{ totals.item4B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item4.xxxxxLQty }}, Adv: {{ totals.item4A.xxxxxLQty }}, Pal: {{ totals.item4B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item4.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item4A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item4B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 5 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item5.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item5.sQty }}, Adv: {{ totals.item5A.sQty }}, Pal: {{ totals.item5B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.mQty }}, Adv: {{ totals.item5A.mQty }}, Pal: {{ totals.item5B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.lQty }}, Adv: {{ totals.item5A.lQty }}, Pal: {{ totals.item5B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.xLQty }}, Adv: {{ totals.item5A.xLQty }}, Pal: {{ totals.item5B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.xxLQty }}, Adv: {{ totals.item5A.xxLQty }}, Pal: {{ totals.item5B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.xxxLQty }}, Adv: {{ totals.item5A.xxxLQty }}, Pal: {{ totals.item5B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.xxxxLQty }}, Adv: {{ totals.item5A.xxxxLQty }}, Pal: {{ totals.item5B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item5.xxxxxLQty }}, Adv: {{ totals.item5A.xxxxxLQty }}, Pal: {{ totals.item5B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item5.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item5A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item5B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 6 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item6.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item6.sQty }}, Adv: {{ totals.item6A.sQty }}, Pal: {{ totals.item6B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.mQty }}, Adv: {{ totals.item6A.mQty }}, Pal: {{ totals.item6B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.lQty }}, Adv: {{ totals.item6A.lQty }}, Pal: {{ totals.item6B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.xLQty }}, Adv: {{ totals.item6A.xLQty }}, Pal: {{ totals.item6B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.xxLQty }}, Adv: {{ totals.item6A.xxLQty }}, Pal: {{ totals.item6B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.xxxLQty }}, Adv: {{ totals.item6A.xxxLQty }}, Pal: {{ totals.item6B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.xxxxLQty }}, Adv: {{ totals.item6A.xxxxLQty }}, Pal: {{ totals.item6B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item6.xxxxxLQty }}, Adv: {{ totals.item6A.xxxxxLQty }}, Pal: {{ totals.item6B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item6.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item6A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item6B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 7 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item7.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>XS</strong></th>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item7.xSQty }}, Adv: {{ totals.item7A.xSQty }}, Pal: {{ totals.item7B.xSQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item7.sQty }}, Adv: {{ totals.item7A.sQty }}, Pal: {{ totals.item7B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item7.mQty }}, Adv: {{ totals.item7A.mQty }}, Pal: {{ totals.item7B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item7.lQty }}, Adv: {{ totals.item7A.lQty }}, Pal: {{ totals.item7B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item7.xLQty }}, Adv: {{ totals.item7A.xLQty }}, Pal: {{ totals.item7B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item7.xxLQty }}, Adv: {{ totals.item7A.xxLQty }}, Pal: {{ totals.item7B.xxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item7.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item7A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item7B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 8 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item8.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>14/16</strong></th>
                                            <th><strong>18/20</strong></th>
                                            <th><strong>22/24</strong></th>
                                            <th><strong>26/28</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item8.sQty }}, Adv: {{ totals.item8A.sQty }}, Pal: {{ totals.item8B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item8.mQty }}, Adv: {{ totals.item8A.mQty }}, Pal: {{ totals.item8B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item8.lQty }}, Adv: {{ totals.item8A.lQty }}, Pal: {{ totals.item8B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item8.xLQty }}, Adv: {{ totals.item8A.xLQty }}, Pal: {{ totals.item8B.xLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item8.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item8A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item8B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 11 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item11.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                            <th><strong>5XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item11.sQty }}, Adv: {{ totals.item11A.sQty }}, Pal: {{ totals.item11B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.mQty }}, Adv: {{ totals.item11A.mQty }}, Pal: {{ totals.item11B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.lQty }}, Adv: {{ totals.item11A.lQty }}, Pal: {{ totals.item11B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.xLQty }}, Adv: {{ totals.item11A.xLQty }}, Pal: {{ totals.item11B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.xxLQty }}, Adv: {{ totals.item11A.xxLQty }}, Pal: {{ totals.item11B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.xxxLQty }}, Adv: {{ totals.item11A.xxxLQty }}, Pal: {{ totals.item11B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.xxxxLQty }}, Adv: {{ totals.item11A.xxxxLQty }}, Pal: {{ totals.item11B.xxxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item11.xxxxxLQty }}, Adv: {{ totals.item11A.xxxxxLQty }}, Pal: {{ totals.item11B.xxxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item11.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item11A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item11B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 10 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item10.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item10.qty }}, Adv: {{ totals.item10A.qty }}, Pal: {{ totals.item10B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item10.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item10A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item10B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 12 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item12.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item12.qty }}, Adv: {{ totals.item12A.qty }}, Pal: {{ totals.item12B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item12.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item12A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item12B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 19 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item19.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item19.qty }}, Adv: {{ totals.item19A.qty }}, Pal: {{ totals.item19B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item19.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item19A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item19B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 20 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item20.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item20.qty }}, Adv: {{ totals.item20A.qty }}, Pal: {{ totals.item20B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item20.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item20A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item20B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 13 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item13.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item13.qty }}, Adv: {{ totals.item13A.qty }}, Pal: {{ totals.item13B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item13.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item13A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item13B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 27 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item27.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item27.qty }}, Adv: {{ totals.item27A.qty }}, Pal: {{ totals.item27B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item27.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item27A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item27B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 14 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item14.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item14.sQty }}, Adv: {{ totals.item14A.sQty }}, Pal: {{ totals.item14B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.mQty }}, Adv: {{ totals.item14A.mQty }}, Pal: {{ totals.item14B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.lQty }}, Adv: {{ totals.item14A.lQty }}, Pal: {{ totals.item14B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.xLQty }}, Adv: {{ totals.item14A.xLQty }}, Pal: {{ totals.item14B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.xxLQty }}, Adv: {{ totals.item14A.xxLQty }}, Pal: {{ totals.item14B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.xxxLQty }}, Adv: {{ totals.item14A.xxxLQty }}, Pal: {{ totals.item14B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item14.xxxxLQty }}, Adv: {{ totals.item14A.xxxxLQty }}, Pal: {{ totals.item14B.xxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item15.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item15A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item15B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 15 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item15.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>S</strong></th>
                                            <th><strong>M</strong></th>
                                            <th><strong>L</strong></th>
                                            <th><strong>XL</strong></th>
                                            <th><strong>2XL</strong></th>
                                            <th><strong>3XL</strong></th>
                                            <th><strong>4XL</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item15.sQty }}, Adv: {{ totals.item15A.sQty }}, Pal: {{ totals.item15B.sQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.mQty }}, Adv: {{ totals.item15A.mQty }}, Pal: {{ totals.item15B.mQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.lQty }}, Adv: {{ totals.item15A.lQty }}, Pal: {{ totals.item15B.lQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.xLQty }}, Adv: {{ totals.item15A.xLQty }}, Pal: {{ totals.item15B.xLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.xxLQty }}, Adv: {{ totals.item15A.xxLQty }}, Pal: {{ totals.item15B.xxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.xxxLQty }}, Adv: {{ totals.item15A.xxxLQty }}, Pal: {{ totals.item15B.xxxLQty }}</td>
                                            <td class="card-title">Scott: {{ totals.item15.xxxxLQty }}, Adv: {{ totals.item15A.xxxxLQty }}, Pal: {{ totals.item15B.xxxxLQty }}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item15.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item15A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item15B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 17 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item17.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item17.qty }}, Adv: {{ totals.item17A.qty }}, Pal: {{ totals.item17B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item17.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item17A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item17B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 18 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item18.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item18.qty }}, Adv: {{ totals.item18A.qty }}, Pal: {{ totals.item18B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item18.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item18A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item18B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 21 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item21.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item21.qty }}, Adv: {{ totals.item21A.qty }}, Pal: {{ totals.item21B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item21.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item21A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item21B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 22 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item22.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item22.qty }}, Adv: {{ totals.item22A.qty }}, Pal: {{ totals.item22B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item22.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item22A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item22B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 spacer"> <!-- Item 23 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item23.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item23.qty }}, Adv: {{ totals.item23A.qty }}, Pal: {{ totals.item23B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item23.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item23A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item23B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 spacer"> <!-- Item 24 -->
                    <div class="card-container">
                        <div class="card border border-primary">
                            <div class="card-title"><h1>{{ totals.item24.description }}</h1></div>
                            <div class="card-body">
                                <p class="card-title"><strong>(Totals)</strong></p>
                                <table class="table table-striped card-title">
                                    <thead>
                                        <tr>
                                            <th><strong>Qty</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="card-title">Scott: {{ totals.item24.qty }}, Adv: {{ totals.item24A.qty }}, Pal: {{ totals.item24B.qty }}</td>
                                        </tr>
                                        <tr>
                                            <td class="card-title">
                                                <strong>Subtotal Scott: </strong>{{ totals.item24.subtotal | currency }}, 
                                                <strong>Subtotal Advantage: </strong>{{ totals.item24A.subtotal | currency }}, 
                                                <strong>Subtotal Palco: </strong>{{ totals.item24B.subtotal | currency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </center>
    </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Header from '@/components/Header.vue'
import Api from '@/services/Api.service.js';

export default {
    name: 'Totals',
    components: {
        Header
    },
    data () {
        return {
            alerts: [],
            totals: {
                item1: { // item1
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item1A: { // item1A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item1B: { // item1B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item2: { // item2
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item2A: { // item2A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item2B: { // item2B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item3: { // item3
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item3A: { // item3A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item3B: { // item3B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item4: { // item4
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item4A: { // item4A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item4B: { // item4B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item5: { // item5
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item5A: { // item5A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item5B: { // item5B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item6: { // item6
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item6A: { // item6A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item6B: { // item6B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item7: { // item7
                    description: '',
                    xSQty: 0,
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    subtotal: 0
                },
                item7A: { // item7A
                    description: '',
                    xSQty: 0,
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    subtotal: 0
                },
                item7B: { // item7B
                    description: '',
                    xSQty: 0,
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    subtotal: 0
                },
                item8: { // item8
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    subtotal: 0
                },
                item8A: { // item8A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    subtotal: 0
                },
                item8B: { // item8B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    subtotal: 0
                },
                item11: { // item11
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item11A: { // item11A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item11B: { // item11B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    xxxxxLQty: 0,
                    subtotal: 0
                },
                item10: { // item10
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item10A: { // item10A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item10B: { // item10B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item12: { // item12
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item12A: { // item12A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item12B: { // item12B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item19: { // item19
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item19A: { // item19A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item19B: { // item19B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item20: { // item20
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item20A: { // item20A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item20B: { // item20B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item13: { // item13
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item13A: { // item13A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item13B: { // item13B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item27: { // item27
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item27A: { // item27A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item27B: { // item27B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item14: { // item14
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item14A: { // item14A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item14B: { // item14B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item15: { // item15
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item15A: { // item15A
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item15B: { // item15B
                    description: '',
                    sQty: 0,
                    mQty: 0,
                    lQty: 0,
                    xLQty: 0,
                    xxLQty: 0,
                    xxxLQty: 0,
                    xxxxLQty: 0,
                    subtotal: 0
                },
                item17: { // item17
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item17A: { // item17A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item17B: { // item17B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item18: { // item18
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item18A: { // item18A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item18B: { // item18B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item21: { // item21
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item21A: { // item21A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item21B: { // item21B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item22: { // item22
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item22A: { // item22A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item22B: { // item22B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item23: { // item23
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item23A: { // item23A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item23B: { // item23B
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item24: { // item24
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item24A: { // item24A
                    description: '',
                    qty: 0,
                    subtotal: 0
                },
                item24B: { // item24B
                    description: '',
                    qty: 0,
                    subtotal: 0
                }
            }
        }
    },
    filters: {
        currency: function (value) {
        //value = value.toFixed(2)
        value+=''
        var x = value.split('.')
        var x1 = x[0]
        var x2 = x.length > 1 ? '.' + x[1] : ''
        var rgx = /(\d+)(\d{3})/
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2')
        }
        var val = x1 + x2
        return '$' + val
        }  
    },
    beforeMount () {
        this.getTotals()
    },
    mounted () {
        //console.log(this.totals)
    },
    methods: {
        closeAlert (index) {
            this.alerts.splice(index, 1)
        },
        getTotals () {
            Api.totals()
            .then(res => {
                this.totals = res.data
            })
            .catch(err => {
                this.alerts.push({ type: 'alert alert-danger alert-dismissable fade show', msg: JSON.stringify(err.response.data) })
            })
            .finally(() => {})
        }   
    }
}
</script>

<style scoped>
.container-fluid {
  margin-bottom: 30px;
  background-image: url("../assets/background2.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card {
    width: 90%;
    background:rgba(0,0,0,0.4)
}
.card-title {
    color: #fff;
    text-align: center;
}
.card-text {
    padding-top: 30px;
    color: #fff;
}
.spacer {
    padding: 10px;
}
</style>