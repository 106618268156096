<template>
  <div class="container-fluid">
    <center>
      <Header />
      <OrderForm />
    </center>
  </div>
</template>

<script>
/** Disclaimer: 
 *  Everything here was created and written exclusively by Bob Kelecava 
 * (who is the sole developer and creator of the Scott Electric website, online ordering system, and online admin tools) 
 * while employed by Scott Electric as an in-house system developer/programmer */ 
import Header from '@/components/Header.vue'
import OrderForm from '@/components/OrderForm.vue'

export default {
  name: 'Home',
  components: {
    Header,
    OrderForm
  }
}
</script>

<style scoped>
.container-fluid {
  margin-bottom: 30px;
  background-image: url("../assets/background2.jpg");
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
